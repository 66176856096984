<template>
  <div class="nav">
    <admin-widget-edit-delete
      v-if="editMenu"
      :item-edited="editItem"
      :item-id=nav.id
      :options="widgetOptionsDelete"
      style="margin-left: 0"
      @toggleEdit="editItem = !editItem"
    ></admin-widget-edit-delete>

    <div v-if="editItem" class="admin-input">
      <textarea v-model="nav.titleEng" rows="1" v-if="isEnglish"></textarea>
      <textarea v-model="nav.title" rows="1" v-else></textarea>
    </div>
    <div v-else>
      <div class="nav__title" v-if="isEnglish">{{ nav.titleEng }}</div>
      <div class="nav__title" v-else>{{ nav.title }}</div>
    </div>
    <div v-if="editItem" class="admin-input">
      <textarea v-model="nav.link " rows="1"></textarea>
    </div>
    <div v-else class="nav__link">{{ nav.link }}</div>
    <div v-if="editItem" class="admin-input">
      <select v-model="nav.componentType">
        <option v-for="(component, index) in getComponentTypes" :key="index" :value="component">{{component}}</option>
      </select>
    </div>
    <div v-else class="nav__component">{{ nav.componentType }}</div>

    <admin-widget-add v-if="editSections && nav.link !== '/contacts'" :options="widgetOptionsAdd"></admin-widget-add>

    <div class="nav__sections" v-if="nav.link !== '/contacts'">
      <admin-nav-page-list-items
        v-for="(section, index) in nav.sections"
        :key="index"
        :edit-pages="editPages"
        :edit-sections="editSections"
        :parent-id="nav.id"
        :navSection="section"
      ></admin-nav-page-list-items>
    </div>
  </div>
</template>

<script>
import AdminNavPageListItems from "@/components/admin/AdminNavPageListItems";
import AdminWidgetEditDelete from "@/components/admin/AdminWidgetEditDelete";
import AdminWidgetAdd from "@/components/admin/AdminWidgetAdd";

export default {
  name: "AdminNavPageListSections",
  components: {
    AdminWidgetEditDelete,
    AdminNavPageListItems,
    AdminWidgetAdd
  },
  props: {
    nav: {
      type: Object,
      default: () => {
      }
    },
    editMenu: {
      type: Boolean,
      default: false
    },
    editSections: {
      type: Boolean,
      default: false
    },
    editPages: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editItem: false,
      widgetOptionsDelete: {
        mutationName: "deleteNavMenu",
        swapItems: {
          mutationNames: ["moveNavMenuUp", "moveNavMenuDown"],
          type: "upDown"
        },
      },
      widgetOptionsAdd: {
        title: "Добавить раздел",
        mutationName: "addNavSection",
        parentId: this.nav.id,
        inputs: [
          {
            label: "Название",
            numberOfRows: 1,
            vModelKeyName: "title"
          },
          {
            label: "Название (Английская версия)",
            numberOfRows: 1,
            vModelKeyName: "titleEng"
          },
          {
            label: "Ссылка",
            numberOfRows: 1,
            vModelKeyName: "link"
          },
          {
            label: "Компонент",
            numberOfRows: 1,
            vModelKeyName: "componentType",
            selectEnable: true,
            selectType: 'components',
          },
          {
            label: "Название картинки",
            numberOfRows: 1,
            vModelKeyName: "image",
            selectEnable: true,
            selectType: 'cardImages',
          }
        ]
      }
    };
  },
  computed: {
    getComponentTypes() {
      return this.$store.getters.getComponentTypes
    },
    getCardImages() {
      return this.$store.getters.getCardImages
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.nav {
  margin-bottom: 30px;

  &__title, &__link, &__component {
    font-size: $fontSizeXL;
    font-weight: 700;
    text-align: center;
  }

  &__component {
    margin-bottom: 20px;
  }

  &__sections {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
  }
}

.admin {
  &-input {
    margin: 0 auto 5px;
    max-width: 100%;
    width: 400px;

    > select {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
    }

    > textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}

</style>