<template>
  <div class="container navigation-edit">
<!--    <page-header></page-header>-->
<!--    Это главная страница для редактирования навигации и разделов сайта-->
<!--    При создании раздела нужно выбирать тип компонента, все изменения сначала идут в store,
        после чего нужно сохранить изменения нажав на кнопку. Создания разделов для toggleBlocks и актов
        пока не протестированы!!! -->
    <admin-buttons-block v-if="checkContentManagerStatus" :options="adminButtonsOptions" />
<!--    Этот блок переключает редактирование разделов, подразделов и страниц отдельно, чтобы не было каши-->
    <div class="navigation-edit__buttons">
      <div class="navigation-edit__button" @click="startEditMenu">{{ editMenu ? "Принять изменения" : "Меню" }}
      </div>
      <div class="navigation-edit__button" @click="startEditSections">
        {{ editSections ? "Принять изменения" : "Разделы" }}
      </div>
      <div class="navigation-edit__button" @click="startEditPages">
        {{ editPages ? "Принять изменения" : "Страницы" }}
      </div>
    </div>
    <admin-widget-add v-if="editMenu" :options="widgetOptionsAdd"></admin-widget-add>
    <div class="navigation-edit__list">
      <admin-nav-page-list-sections
        v-for="(nav, index) in getNavSections"
        :key="index"
        :nav="nav"
        :editMenu="editMenu"
        :editSections="editSections"
        :editPages="editPages"
        class="nav__list">
      </admin-nav-page-list-sections>
    </div>
  </div>
</template>

<script>
//import PageHeader from "@/components/PageHeader";
import AdminButtonsBlock from "@/components/admin/AdminButtonsBlock";
import AdminWidgetAdd from "@/components/admin/AdminWidgetAdd";
import AdminNavPageListSections from "@/components/admin/AdminNavPageListSections";

export default {
  name: "AdminEditNavPage",
  components: {
    AdminNavPageListSections,
    //PageHeader,
    AdminButtonsBlock,
    AdminWidgetAdd
  },
  data() {
    return {
      adminButtonsOptions: {
        actionNameSave: "sendNavSections",
        actionNameCancel: "downloadNavSections"
      },
      editItem: false,
      editMenu: false,
      editSections: false,
      editPages: false,
      componentList: [],
      widgetOptionsAdd: {
        title: "Добавить меню",
        mutationName: "addNavMenu",
        inputs: [
          {
            label: "Название",
            numberOfRows: 1,
            vModelKeyName: "title"
          },
          {
            label: "Название (Английская версия)",
            numberOfRows: 1,
            vModelKeyName: "titleEng"
          },
          {
            label: "Ссылка",
            numberOfRows: 1,
            vModelKeyName: "link"
          },
          {
            label: "Компонент",
            numberOfRows: 1,
            vModelKeyName: "componentType",
            selectEnable: true,
            selectType: 'components',
          },
        ]
      }
    };
  },
  mounted() {
    this.getSelectItems()
  },
  computed: {
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getNavSections() {
      return this.$store.getters.getNavSections
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
  },
  methods: {
    getSelectItems() {
      this.componentList = this.$store.getters.getComponentTypes
    },
    startEditMenu() {
      if (!this.editMenu) {
        this.editMenu = true
        this.editSections = false
        this.editPages = false
      } else {
        this.editMenu = false
      }
    },
    startEditSections() {
      if (!this.editSections) {
        this.editMenu = false
        this.editSections = true
        this.editPages = false
      } else {
        this.editSections = false
      }
    },
    startEditPages() {
      if (!this.editPages) {
        this.editMenu = false
        this.editSections = false
        this.editPages = true
      } else {
        this.editPages = false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.navigation-edit {

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }

  &__button {
    width: 150px;
    padding: 10px 15px;
    font-size: $fontSizeL;
    text-align: center;
    background-color: #E7E7E8;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      color: $white-color;
      background-color: $primary-color;
    }
  }

  &__list {
    width: 100%;
    margin-bottom: 30px;
  }
}

</style>