<template>
  <div class="section">
    <div v-if="editItem" class="admin-input">
      <textarea v-model="navSection.titleEng" rows="1" v-if="isEnglish"></textarea>
      <textarea v-model="navSection.title" rows="1" v-else></textarea>
    </div>
    <div v-else>
      <div class="section__title" v-if="isEnglish">{{ navSection.titleEng }}</div>
      <div class="section__title" v-else>{{ navSection.title }}</div>
    </div>

    <div v-if="editItem" class="admin-input">
      <textarea v-model="navSection.link" rows="1"></textarea>
    </div>
    <div v-else class="section__link">{{ navSection.link }}</div>

    <div v-if="editItem" class="admin-input">
      <select v-model="navSection.componentType">
        <option v-for="(component, index) in getComponentTypes" :key="index" :value="component">{{component}}</option>
      </select>
    </div>
    <div v-else class="section__component">{{ navSection.componentType }}</div>

    <div v-if="editItem" class="admin-input">
      <select v-model="navSection.image">
        <option v-for="(image, index) in getCardImages" :key="index" :value="image">{{image}}</option>
      </select>
    </div>
    <div v-else class="section__image">{{ navSection.image }}</div>

    <admin-widget-edit-delete
      v-if="editSections"
      :item-edited="editItem"
      :item-id="navSection.id"
      :options="widgetOptionsDelete"
      style="margin-left: 0"
      @toggleEdit="editItem = !editItem"
    ></admin-widget-edit-delete>

    <div class="section__list">
      <admin-widget-add v-if="editPages" :options="widgetOptionsAdd"></admin-widget-add>
      <admin-nav-page-item
        v-for="(item, index) in navSection.items"
        :key="index"
        :edit-pages="editPages"
        :parent-id="navSection.id"
        :section-id='navSection.parentId'
        :navItem="item"
      ></admin-nav-page-item>
    </div>
  </div>
</template>

<script>
import AdminNavPageItem from "@/components/admin/AdminNavPageItem";
import AdminWidgetAdd from "@/components/admin/AdminWidgetAdd";
import AdminWidgetEditDelete from "@/components/admin/AdminWidgetEditDelete";

export default {
  name: "AdminNavPageListItems",
  components: {
    AdminWidgetAdd,
    AdminNavPageItem,
    AdminWidgetEditDelete
  },
  props: {
    navSection: {
      type: Object,
      default: () => {
      }
    },
    editSections: {
      type: Boolean,
      default: false
    },
    editPages: {
      type: Boolean,
      default: false
    },
    parentId: {
      type: Number,
    }
  },
  data() {
    return {
      editItem: false,
      widgetOptionsDelete: {
        mutationName: "deleteNavSection",
        swapItems: {
          mutationNames: ["moveNavSectionUp", "moveNavSectionDown"],
          type: "leftRight"
        },
        parentId: this.navSection.parentId,
      },
      widgetOptionsAdd: {
        title: "Добавить страницу",
        mutationName: "addNavItem",
        parentId: this.navSection.id,
        sectionId: this.navSection.parentId,
        inputs: [
          {
            label: "Название",
            numberOfRows: 1,
            vModelKeyName: "title"
          },
          {
            label: "Название (Английская версия)",
            numberOfRows: 1,
            vModelKeyName: "titleEng"
          },
          {
            label: "Ссылка (начиная со знака /)",
            numberOfRows: 1,
            vModelKeyName: "link"
          },
          {
            label: "Компонент",
            numberOfRows: 1,
            vModelKeyName: "componentType",
            selectEnable: true,
            selectType: 'components',
          },
          {
            label: "Название картинки",
            numberOfRows: 1,
            vModelKeyName: "image",
            selectEnable: true,
            selectType: 'cardImages',
          }
        ]
      }
    };
  },
  computed: {
    getComponentTypes() {
      return this.$store.getters.getComponentTypes
    },
    getCardImages() {
      return this.$store.getters.getCardImages
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.section {
  margin-bottom: 30px;

  &__title, &__link, &__component, &__image {
    font-size: $fontSizeL;
    text-align: center;
  }

  &__image {
    margin-bottom: 20px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.admin {

  &-input {
    max-width: 100%;
    width: 400px;
    margin-bottom: 5px;

    > select {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
    }

    > textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}

</style>