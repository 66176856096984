<template>
  <div class="item">
    <div v-if="editItem" class="admin-input">
      <textarea v-model="navItem.titleEng" rows="1" v-if="isEnglish"></textarea>
      <textarea v-model="navItem.title" rows="1" v-else></textarea>
    </div>
    <div v-else>
      <div class="item__title" v-if="isEnglish">{{ navItem.titleEng }}</div>
      <div class="item__title" v-else>{{ navItem.title }}</div>
    </div>

    <div v-if="editItem" class="admin-input">
      <textarea v-model="navItem.link" rows="1"></textarea>
    </div>
    <div v-else class="item__link">{{ navItem.link }}</div>

    <div v-if="editItem" class="admin-input">
      <select v-model="navItem.componentType">
        <option v-for="(component, index) in getComponentTypes" :key="index" :value="component">{{component}}</option>
      </select>
    </div>
    <div v-else class="item__component">{{ navItem.componentType }}</div>

    <div v-if="editItem" class="admin-input">
      <select v-model="navItem.image">
        <option v-for="(image, index) in getCardImages" :key="index" :value="image">{{image}}</option>
      </select>
    </div>
    <div v-else class="item__image">{{ navItem.image }}</div>

    <admin-widget-edit-delete
      v-if="editPages"
      :item-edited="editItem"
      :item-id=navItem.id
      :options="widgetOptionsDelete"
      style="margin-left: 0"
      @toggleEdit="editItem = !editItem"
    ></admin-widget-edit-delete>
  </div>
</template>

<script>
import AdminWidgetEditDelete from "@/components/admin/AdminWidgetEditDelete";

export default {
  name: "AdminNavPageItem",
  components: {
    AdminWidgetEditDelete
  },
  props: {
    navItem: {
      type: Object,
      default: () => {
      }
    },
    parentId: {
      type: Number
    },
    sectionId: {
      type: Number
    },
    editPages: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editItem: false,
      widgetOptionsDelete: {
        mutationName: "deleteNavItem",
        swapItems: {
          mutationNames: ["moveNavItemUp", "moveNavItemDown"],
          type: "upDown"
        },
        parentId: this.parentId,
        sectionId: this.sectionId,
      }
    };
  },
  computed: {
    getComponentTypes() {
      return this.$store.getters.getComponentTypes
    },
    getCardImages() {
      return this.$store.getters.getCardImages
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  &-title {
  }
}

.admin {

  &-input {
    max-width: 100%;
    width: 400px;
    margin-bottom: 5px;

    > select {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
    }

    > textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}

</style>